.pbox {
  text-align: center;
  border: 1px solid black;
}

.pbox:hover {
  color: white;
  background-color: black;
  transition: 150ms;
}

.pbox-c {
  text-align: center;
  color: white;
  background-color: black;
}

.lbox {
  height: 225px;
  width: 250px;
  text-align: center;
  border: 1px solid black;
}

.lbox > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lbox:hover {
  color: white;
  background-color: black;
  transition: 150ms;
}

.lbox-c {
  height: 225px;
  width: 250px;
  text-align: center;
  color: white;
  background-color: black;
}

.pform {
  min-width: 280px;
  width: 50%;
  margin: 0 auto;
}

.pformbtn {
  background: none;
  border: 2px solid black;
  padding: 0.5em 3em;
}

.pformbtn:hover {
  background-color: black;
  color: white;
  transition: 150ms;
}