.navbar {
    background-color: #000;
}

.separator {
    width: 1px;
    background-color: #ffffff59;
    height: 80%;
}

.navbar-nav .nav-link {
    color: rgb(212, 212, 212) !important;
}

.navbar-nav .nav-link:hover {
    color: rgb(255, 255, 255) !important;
}
 
.nav-profile {
    padding-top: 85px;
    z-index: 2;
}