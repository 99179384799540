@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

/* PERCENTAGE WIDTH */

.w-30 {
   width: 30%;
}
.w-40 {
   width: 40%;
}
.w-80 {
   width: 80%;
}
.w-90 {
   width: 90%;
}

/* VIEW HEIGHT */

.vh-40 {
   height: 40vh;
}
.vh-50 {
   height: 50vh;
}
.vh-60 {
   height: 60vh;
}
.vh-70 {
   height: 70vh;
}
.vh-80 {
   height: 80vh;
}

/* HEIGHT */

.h-60 {
   height: 60%;
}
.h-80 {
   height: 80%;
}

/* FW */
.fw-500 {
   font-weight: 500;
}
.fw-600 {
   font-weight: 600;
}
.fw-700 {
   font-weight: 700;
}
.fw-800 {
   font-weight: 800;
}

/* general style */

body {
   overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Shadow */

.shadow-1 {
   box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

/* BODY */

html  {
   width: 100%;
   height: 100%;
   margin: 0px;
   padding: 0px;
   overflow-x: hidden; 
}

.loginwrapper {
   justify-content: center;
   align-items: center;
}

.formcontainer {
   border-radius: 8px;
   justify-content: center;
   align-items: center;
}

.login {
   max-width: 400px;
   width: 90%;
}

.signup {
   max-width: 750px;
   width: 100%;
   padding: 1rem 2rem;
}

.alert {
   border-top-left-radius: 0;
   border-top-right-radius: 0;
   margin: 0;
}

.logoloading {
   width: 5em;
   height: auto;
   animation-name: breathing;
   animation-duration: 1000ms;
   animation-timing-function: ease-in-out;
   animation-delay: 0;
   animation-iteration-count: infinite;
}

@keyframes breathing {
   0% {
     -webkit-transform: scale(0.9);
     -ms-transform: scale(0.9);
     transform: scale(0.9);
   }
 
   50% {
     -webkit-transform: scale(1);
     -ms-transform: scale(1);
     transform: scale(1);
   }
 
   100% {
     -webkit-transform: scale(0.9);
     -ms-transform: scale(0.9);
     transform: scale(0.9);
   }
 }

 .hiddenRow {
   padding: 0 !important;
}

.pfp {
   height: 10em;
   width: 10em;
   border-radius: 50%;
   overflow: hidden;

   display: flex;
   justify-content: center;
   align-items: center;
}

 .btn-more {
    background-color: none;
    text-decoration: none;
    border: 3px solid rgb(255, 255, 255);
    padding: 8px 50px;
    color: rgb(255, 255, 255);
 }

 .btn-more:hover {
   background-color: #1b1b1b;
   color: rgb(255, 255, 255);
}

.countrybutton {
   border: 2px solid #fff;
   background-color: #000;
   color: #fff;
   padding: 7px 25px;
   transition: 200ms;
   text-decoration: none;
}

.countrybutton:hover {
   background-color: #ffffff;
   color: #000;
}

.selected {
   background-color: rgb(192, 192, 192);
}

.modal90w {
   min-width: 90%;
}

@media (max-width: 768px) {
   .rankingNewButton {
      margin-bottom: 1rem;
   }
}

.bugs-popup {
   z-index: 99;
   position: fixed;
   bottom: 0;
   right: 0;
}

.bugs-popup-f {
   padding: 0.1em;
   background-color: #fff;
   border-radius: 4px 0px 0px 0px;
}

.react-date-picker__wrapper {
   border: none !important;
}

.datePicker {
   border: none;
}

.errorText {
   font-size: 0.83em;
   font-style: italic;
   color: red;
   text-align: left;
   padding: 0;
}

.successText {
   font-size: 0.83em;
   font-style: italic;
   color: green;
   text-align: left;
   padding: 0;
}

.filter-link:hover {
   cursor: pointer;
}

.menu-btn {
   margin-left: 1rem;
}

@media (min-width: 1000px) {
   .menu-btn {
      margin-left: 0;
      border: 1px solid white;
      border-radius: 4px;
   }

   .menu-btn:hover > span {
      color: #000;
   }

   .menu-btn:hover {
      background-color: #fff;
   }

   .dropdown-toggle:hover {
      color:#000 !important;
   }
}

.modal-open[style] {
   padding-right: 0px !important;
}

.children-no-border > * {
   border-radius: 0;
}

.children-no-border {
   margin-top: -1px;
}

.children-no-border > button {
   margin: -1px;
}

.threedots {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}