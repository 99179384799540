
.text-h1{

    display: 5;
    font-weight: bold;
    color: white; 
    font-size: 50px;
    text-shadow: 2px 2px 2px black ;

}

@media (max-width: 768px) {
    .text-h1{
        font-size: 30px;
        padding-bottom: 5;
        margin-top: 4;
    }
  }

  .text-span{
    display: 5;
    color:white;
    text-shadow: 1px 1px 2px black ;
    font-size: 30px;
  }
  @media (max-width: 768px) {
    .text-span{
        display: 5;
        color:white;
        font-size: 20px;
        text-shadow: 1px 1px 2px black ;
      }
  }