.coaches-fimage {
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-image: url("./frontimage7.jpg");
}




.coaches-fimage::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,7,0.65);
  content: '';
} 

.coach-photo-container {
  width: auto;
}

.texto-container {
  text-align: justify;
}

@media (max-width: 768px) {
  .texto-container {
    text-align: left; 
    font-size: 1rem;
  }
}
