/* HEADER */

.header {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("./PrincipalHome.png");
  overflow: hidden;  
  height: 100vh; 
  max-width: 100%;
  width: 100%;
}

/* .header::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,7,0.65);
  content: '';
}  */

.img-btn {
  width: 30%;
  padding: 15px 0px;
  min-width: 250px;
}

.nav-item .separator {
  width: 1px;
  background-color: #999999;
  height: 40px;
}

/* NOSOTROS */

#nosotros {
  padding-top: 125px;
  background-color: #000;
}

@media (max-width: 992px) {
  #nosotros {
     padding: 100px 0px;
  }
}

.nosotros-line {
  width: 70%;
  height: 1px;
  background-color: #fff;
}

/* BENEFICIOS */

#trainers {
  background-color: #000;
  padding: 75px 0px 75px 0px;
}

@media (max-width: 768px) {
  #trainers {
     
    padding: 75px 0px 75px 0px;
  }
  
  #trainers .txt {
     text-align: center;
     padding-top: 4rem;
     padding-bottom: 2.5rem;
  }
 
}
#results {
  background-color: #000;
  padding: 75px 0px 75px 0px;
}

@media (max-width: 768px) {
  #results {

    padding: 75px 0px 75px 0px;
  }

  #results .txt {
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 2.5rem;
  }

}

/* SUBSCRIPCIONES */

.card-img {
  object-fit: cover;
  border-radius: 2%;
}

.cardov {
  color: inherit;
  max-width: 450px;
  border-radius: 2%;
}

.cardov::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000783;
  content: '';
  border-radius: 2%;
}

.cardov:hover {
  color: inherit;
  transition: all 100ms ease-in-out;
  transform: scale(1.03);
}

@media (min-width: 992px) {
  .sub {
     padding: 0px 50px;
  }
}

/* CAPACITACIONES y CONTACTO */

#capacitaciones {
  background-color: #000;
}

.contacto {
  background-color: #000;
}

/* AFILIADOS */

.afiliados {
  background-color: rgb(0, 0, 0);
  position: relative;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.afiliados h2 {
   font-weight: 800;
   font-style: italic;
   text-align: center;
}

.slick-slide {
  opacity: .6;
}

.slick-slide img {
  margin: 0 auto;
  border: 1px solid white;
}

.slick-center{
  opacity: 1;
}

.slide img {
   width: 250px;
   height: 150px;
}

.afiliados-wrapper {
   padding: 50px;
   margin: 0 auto;
}

@media only screen and (min-width: 1340px) {
   .afiliados-wrapper { 
       width: 70%;
   }
}

.slick-arrow::before {
  color: white;
}

.slick-dots li button:before {
  color: #fff;
}

.slick-dots li.slick-active button:before {
  color:white !important;
}

/* FOOTER */

.footer {
  background-color: #000;
}


/* CAPACITATE */


#capcon{
  background-color: #000;

}

.img-thumbnail {
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 100px;
  border: none;
  height: 100px;
}
.socialsquare{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

