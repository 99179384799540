@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
/* PERCENTAGE WIDTH */

.w-30 {
   width: 30%;
}
.w-40 {
   width: 40%;
}
.w-80 {
   width: 80%;
}
.w-90 {
   width: 90%;
}

/* VIEW HEIGHT */

.vh-40 {
   height: 40vh;
}
.vh-50 {
   height: 50vh;
}
.vh-60 {
   height: 60vh;
}
.vh-70 {
   height: 70vh;
}
.vh-80 {
   height: 80vh;
}

/* HEIGHT */

.h-60 {
   height: 60%;
}
.h-80 {
   height: 80%;
}

/* FW */
.fw-500 {
   font-weight: 500;
}
.fw-600 {
   font-weight: 600;
}
.fw-700 {
   font-weight: 700;
}
.fw-800 {
   font-weight: 800;
}

/* general style */

body {
   overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Shadow */

.shadow-1 {
   box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

/* BODY */

html  {
   width: 100%;
   height: 100%;
   margin: 0px;
   padding: 0px;
   overflow-x: hidden; 
}

.loginwrapper {
   justify-content: center;
   align-items: center;
}

.formcontainer {
   border-radius: 8px;
   justify-content: center;
   align-items: center;
}

.login {
   max-width: 400px;
   width: 90%;
}

.signup {
   max-width: 750px;
   width: 100%;
   padding: 1rem 2rem;
}

.alert {
   border-top-left-radius: 0;
   border-top-right-radius: 0;
   margin: 0;
}

.logoloading {
   width: 5em;
   height: auto;
   animation-name: breathing;
   animation-duration: 1000ms;
   animation-timing-function: ease-in-out;
   animation-delay: 0;
   animation-iteration-count: infinite;
}

@keyframes breathing {
   0% {
     transform: scale(0.9);
   }
 
   50% {
     transform: scale(1);
   }
 
   100% {
     transform: scale(0.9);
   }
 }

 .hiddenRow {
   padding: 0 !important;
}

.pfp {
   height: 10em;
   width: 10em;
   border-radius: 50%;
   overflow: hidden;

   display: flex;
   justify-content: center;
   align-items: center;
}

 .btn-more {
    background-color: none;
    text-decoration: none;
    border: 3px solid rgb(255, 255, 255);
    padding: 8px 50px;
    color: rgb(255, 255, 255);
 }

 .btn-more:hover {
   background-color: #1b1b1b;
   color: rgb(255, 255, 255);
}

.countrybutton {
   border: 2px solid #fff;
   background-color: #000;
   color: #fff;
   padding: 7px 25px;
   transition: 200ms;
   text-decoration: none;
}

.countrybutton:hover {
   background-color: #ffffff;
   color: #000;
}

.selected {
   background-color: rgb(192, 192, 192);
}

.modal90w {
   min-width: 90%;
}

@media (max-width: 768px) {
   .rankingNewButton {
      margin-bottom: 1rem;
   }
}

.bugs-popup {
   z-index: 99;
   position: fixed;
   bottom: 0;
   right: 0;
}

.bugs-popup-f {
   padding: 0.1em;
   background-color: #fff;
   border-radius: 4px 0px 0px 0px;
}

.react-date-picker__wrapper {
   border: none !important;
}

.datePicker {
   border: none;
}

.errorText {
   font-size: 0.83em;
   font-style: italic;
   color: red;
   text-align: left;
   padding: 0;
}

.successText {
   font-size: 0.83em;
   font-style: italic;
   color: green;
   text-align: left;
   padding: 0;
}

.filter-link:hover {
   cursor: pointer;
}

.menu-btn {
   margin-left: 1rem;
}

@media (min-width: 1000px) {
   .menu-btn {
      margin-left: 0;
      border: 1px solid white;
      border-radius: 4px;
   }

   .menu-btn:hover > span {
      color: #000;
   }

   .menu-btn:hover {
      background-color: #fff;
   }

   .dropdown-toggle:hover {
      color:#000 !important;
   }
}

.modal-open[style] {
   padding-right: 0px !important;
}

.children-no-border > * {
   border-radius: 0;
}

.children-no-border {
   margin-top: -1px;
}

.children-no-border > button {
   margin: -1px;
}

.threedots {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}
.navbar {
    background-color: #000;
}

.separator {
    width: 1px;
    background-color: #ffffff59;
    height: 80%;
}

.navbar-nav .nav-link {
    color: rgb(212, 212, 212) !important;
}

.navbar-nav .nav-link:hover {
    color: rgb(255, 255, 255) !important;
}
 
.nav-profile {
    padding-top: 85px;
    z-index: 2;
}

.text-h1{

    display: 5;
    font-weight: bold;
    color: white; 
    font-size: 50px;
    text-shadow: 2px 2px 2px black ;

}

@media (max-width: 768px) {
    .text-h1{
        font-size: 30px;
        padding-bottom: 5;
        margin-top: 4;
    }
  }

  .text-span{
    display: 5;
    color:white;
    text-shadow: 1px 1px 2px black ;
    font-size: 30px;
  }
  @media (max-width: 768px) {
    .text-span{
        display: 5;
        color:white;
        font-size: 20px;
        text-shadow: 1px 1px 2px black ;
      }
  }
.container-entrenadores {
    padding-bottom: 5;
  }
  
  @media (max-width: 768px) {
    .container-entrenadores {

        padding-bottom: 5;
        margin-top: 4;
        padding-top: 5;
        margin-bottom: 5;
    }
  }
/* HEADER */

.header {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(/static/media/PrincipalHome.7f2bda10.png);
  overflow: hidden;  
  height: 100vh; 
  max-width: 100%;
  width: 100%;
}

/* .header::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,7,0.65);
  content: '';
}  */

.img-btn {
  width: 30%;
  padding: 15px 0px;
  min-width: 250px;
}

.nav-item .separator {
  width: 1px;
  background-color: #999999;
  height: 40px;
}

/* NOSOTROS */

#nosotros {
  padding-top: 125px;
  background-color: #000;
}

@media (max-width: 992px) {
  #nosotros {
     padding: 100px 0px;
  }
}

.nosotros-line {
  width: 70%;
  height: 1px;
  background-color: #fff;
}

/* BENEFICIOS */

#trainers {
  background-color: #000;
  padding: 75px 0px 75px 0px;
}

@media (max-width: 768px) {
  #trainers {
     
    padding: 75px 0px 75px 0px;
  }
  
  #trainers .txt {
     text-align: center;
     padding-top: 4rem;
     padding-bottom: 2.5rem;
  }
 
}
#results {
  background-color: #000;
  padding: 75px 0px 75px 0px;
}

@media (max-width: 768px) {
  #results {

    padding: 75px 0px 75px 0px;
  }

  #results .txt {
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 2.5rem;
  }

}

/* SUBSCRIPCIONES */

.card-img {
  object-fit: cover;
  border-radius: 2%;
}

.cardov {
  color: inherit;
  max-width: 450px;
  border-radius: 2%;
}

.cardov::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000783;
  content: '';
  border-radius: 2%;
}

.cardov:hover {
  color: inherit;
  transition: all 100ms ease-in-out;
  transform: scale(1.03);
}

@media (min-width: 992px) {
  .sub {
     padding: 0px 50px;
  }
}

/* CAPACITACIONES y CONTACTO */

#capacitaciones {
  background-color: #000;
}

.contacto {
  background-color: #000;
}

/* AFILIADOS */

.afiliados {
  background-color: rgb(0, 0, 0);
  position: relative;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.afiliados h2 {
   font-weight: 800;
   font-style: italic;
   text-align: center;
}

.slick-slide {
  opacity: .6;
}

.slick-slide img {
  margin: 0 auto;
  border: 1px solid white;
}

.slick-center{
  opacity: 1;
}

.slide img {
   width: 250px;
   height: 150px;
}

.afiliados-wrapper {
   padding: 50px;
   margin: 0 auto;
}

@media only screen and (min-width: 1340px) {
   .afiliados-wrapper { 
       width: 70%;
   }
}

.slick-arrow::before {
  color: white;
}

.slick-dots li button:before {
  color: #fff;
}

.slick-dots li.slick-active button:before {
  color:white !important;
}

/* FOOTER */

.footer {
  background-color: #000;
}


/* CAPACITATE */


#capcon{
  background-color: #000;

}

.img-thumbnail {
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 100px;
  border: none;
  height: 100px;
}
.socialsquare{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}


.coaches-fimage {
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-image: url(/static/media/frontimage7.a2e95645.jpg);
}




.coaches-fimage::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,7,0.65);
  content: '';
} 

.coach-photo-container {
  width: auto;
}

.texto-container {
  text-align: justify;
}

@media (max-width: 768px) {
  .texto-container {
    text-align: left; 
    font-size: 1rem;
  }
}

.pbox {
  text-align: center;
  border: 1px solid black;
}

.pbox:hover {
  color: white;
  background-color: black;
  transition: 150ms;
}

.pbox-c {
  text-align: center;
  color: white;
  background-color: black;
}

.lbox {
  height: 225px;
  width: 250px;
  text-align: center;
  border: 1px solid black;
}

.lbox > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lbox:hover {
  color: white;
  background-color: black;
  transition: 150ms;
}

.lbox-c {
  height: 225px;
  width: 250px;
  text-align: center;
  color: white;
  background-color: black;
}

.pform {
  min-width: 280px;
  width: 50%;
  margin: 0 auto;
}

.pformbtn {
  background: none;
  border: 2px solid black;
  padding: 0.5em 3em;
}

.pformbtn:hover {
  background-color: black;
  color: white;
  transition: 150ms;
}
