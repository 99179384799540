.container-entrenadores {
    padding-bottom: 5;
  }
  
  @media (max-width: 768px) {
    .container-entrenadores {

        padding-bottom: 5;
        margin-top: 4;
        padding-top: 5;
        margin-bottom: 5;
    }
  }